<template>
  <b-card no-body>
    <b-card-header>
      <b-cart-title><span style="font-weight: bold; font-size: 1.2em"><feather-icon icon="UserIcon" size="26" /> {{ id ? 'Sửa Thông Tin Nhân Viên' : 'Tạo Nhân Viên Mới'}}</span></b-cart-title>
      <b-card-sub-title>
        <b-button variant="success" @click="validationForm"><feather-icon icon="SaveIcon" /> {{ id ? 'Cập Nhật' : 'Tạo Mới'}}</b-button>
      </b-card-sub-title>
    </b-card-header>
    <b-card-body>
      <validation-observer ref="createRole">
        <b-row>
          <b-col cols="6">
            <b-row>
              <b-col cols="12">
                <b-card title="Tên Nhóm Quyền" header-bg-variant="info">
                  <b-form-group label="">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                      :customMessages="{['required']: 'Không được để trống'}"
                    >
                      <b-form-input
                        v-model="form.name" 
                        :state="errors.length > 0 ? false: null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-card>
              </b-col>
              <b-col>
                <role-action :form="form" :roles="data.roles"/>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <div style="margin-top: 10px">
              <role-status :form="form" :status="data.status"/>
            </div>
          </b-col>
            </b-row>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BCardBody, BCardSubTitle, BCardHeader, BFormInput, BFormDatepicker, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

import RoleAction from './components/RoleAction.vue'
import RoleStatus from './components/RoleStatus.vue'

import Role from './components/Role.vue'
export default {
  props: {
    id: {
      type: Number,
      default: null,
    }
  },
  components: {
    ValidationProvider, ValidationObserver,
    BFormGroup, BFormInput,
    Role,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BRow,
    BCol,
    BButton,
    BCard,
    FeatherIcon,
    RoleAction,
    RoleStatus,
},
  methods: {
    validationForm() {
      this.$refs.createRole.validate().then(success => {
        if(success) {
          console.log('success')
        }
      })
    },
  },
  data() {
    return {
      data: {
        status: [
          {
            id: 1, name: 'Khai Thác Lại', level: 0, roles: [
              { value: 1, text: 'Chọn Tất Cả', },
             { value: 2, text: 'Chỉ Xem',},
             { value: 3, text: 'Xem và Sửa',}
            ]
          },
          {
            id: 2, name: 'Chưa Xác Nhận', level: 1, roles: [{
             value: 1, text: 'Chọn Tất Cả',},
            {  value: 2, text: 'Chỉ Xem',},
             { value: 3, text: 'Xem và Sửa',
            }]
          },
          {
            id: 4, name: 'Gọi Máy Bận', level: 1, roles: [{
              value: 1, text: 'Chọn Tất Cả',},
             { value: 2, text: 'Chỉ Xem',},
            {  value: 3, text: 'Xem và Sửa',
            }]
          },
          {
            id: 5, name: 'Không Nghe Điện', level: 1, roles: [{
              value: 1, text: 'Chọn Tất Cả',},
             { value: 2, text: 'Chỉ Xem',},
           {   value: 3, text: 'Xem và Sửa',
            }]
          },
          {
            id: 3, name: 'Hủy', level: 1,
          },
        ],
        roles: [
          {
            id: 1, name: 'Xem Khối Báo Cáo MKT', level: 0, color: '#fff',
          },
          {
            id: 2, name: 'Quản lý khách hàng', level: 1, color: '#fff',
          },
          {
            id: 3, name: 'Xem khối báo cáo trực page, tình trạng xử lý và chia đơn', level: 1, color: '#fff',
          },
          {
            id: 4, name: 'Xem khối báo cáo Sale', level: 1, color: '#fff',
          },
          {
            id: 5, name: 'Xem doanh thu sale', level: 1, color: '#fff',
          },
        ]
      },
      form: {
        roles: [],
        status: [],
        name: null,
      },
      required,
    }
  },
}
</script>
<style>
  .color-input{
    width: 50%
  }
  .ft-gutters-padding-10px {
    padding-right: 10px!important;
  }
</style>
