<template>
  <app-collapse accordion>
    <app-collapse-item
      :is-visible="true"
      header-bg-variant="secondary"
    >
      <template #header>
        <b-card-title class="text-white"><feather-icon icon="CheckIcon" /> Quyền</b-card-title>
      </template>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import {
  BCardBody, BCardTitle, BCardSubTitle, BCardHeader, BFormInput, BFormDatepicker, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  props: {
    form: {
      type: Object,
    }
  },
  components: {
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormDatepicker,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    FeatherIcon,
    AppCollapse,
    AppCollapseItem,
},
}
</script>

<style>

</style>