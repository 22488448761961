<template>
  <app-collapse accordion>
    <app-collapse-item
      :isVisible="true"
      headerBgVariant="secondary"
    >
      <template #header>
        <b-card-title class="text-white"><feather-icon icon="CheckIcon" /> Tình Trạng Kích Hoạt</b-card-title>
      </template>
      <table class="table table-bordered">
        <tbody>
          <tr v-for="(st, k) in status" :key="k">
            <td>
              {{ st.name }}
            </td>
            <td>
              <b-form-select :options="st.roles" @change="selectedForm($event, k, st)">{{ status.name }}</b-form-select>
            </td>
          </tr>
        </tbody>
      </table>
        {{ this.form.status }}
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import {
  BCardBody, BFormCheckbox, BCardTitle, BCardSubTitle, BCardHeader, BFormInput, BFormDatepicker, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  created () {
    this.$http.get('/api-test').then(res => {
      console.log('view',res);
    })
  },
  methods: {
    selectedForm(e, kk, st) {
      let opt = {
        id: st.id,
        options: e,
      }
      if (this.form.status.length === 0) {
        this.form.status.push(opt)
      } else {
        // nếu đã tồn tại thì k update
        let Index = this.form.status.findIndex((item) => item.id === st.id)
        if(Index !== -1) {
          //tìm thấy 
          this.form.status[Index] = st
        }else {
          //không tìm thấy thêm
          this.form.status.push(st)
        }
        console.log('change', Index)
      }
    },
  },
  data() {
    return {
      statusOptions: [{
        value: null, text: 'Chọn Quyền',
      },{
        value: 1, text: 'Chọn Tất Cả',
      },{
        value: 2, text: 'Xem',
      },{
        value: 3, text: 'Xem và Sửa',
      },],
      defaultSelected: null,
      
    }
  },
  props: {
    form: {
      type: Object,
    },
    status: {
      type: Array
    }
  },
  components: {
    BFormCheckbox, 
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormDatepicker,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    FeatherIcon,
    AppCollapse,
    AppCollapseItem,
},
}
</script>

<style>

</style>